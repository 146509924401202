<template>
  <div class="Setting_wrapper">
    <div class="setting_main">
      <el-alert
        title="温馨提示：若活动页出现”已停止访问该网页“的提示，请优先进行切换URL；若无效，请联系相关技术人员"
        type="warning"
        :closable="false"
      ></el-alert>
      <div class="url_main">
        <p class="view_title mt20">地址信息</p>
        <div class="mt10 url-tips">
          如需增加URL切换次数，请联系相关技术人员进行发版处理
        </div>
        <div class="view_wrap">
          <div class="view_detail ml20">
            <div style="font-size: 20px">活动首页</div>
            <div style="font-size: 16px; margin-top: 10px">
              当前可切换URL次数：
              <span style="color: orange">{{ switchNum || 0 }} </span> 次
            </div>
            <el-button type="primary" :disabled="!switchNum" @click="changeUrl(0)" class="mt10">切换URL</el-button>
          </div>
          <div class="view_detail ml20">
            <div style="font-size: 20px">秒杀页面</div>
            <div style="font-size: 16px; margin-top: 10px">
              当前可切换URL次数：
              <span style="color: orange">{{ switch_seckill_num || 0 }} </span> 次
            </div>
            <el-button type="primary" @click="changeUrl(1)" :disabled="!switch_seckill_num" class="mt10">切换URL</el-button>
          </div>
          <div class="view_detail ml20">
            <div style="font-size: 20px">集赞页面</div>
            <div style="font-size: 16px; margin-top: 10px">
              当前可切换URL次数：
              <span style="color: orange">{{ switch_collection_num || 0 }} </span> 次
            </div>
            <el-button type="primary" @click="changeUrl(2)" :disabled="!switch_collection_num" class="mt10">切换URL</el-button>
          </div>
          <div class="view_detail ml20">
            <div style="font-size: 20px">我的页面</div>
            <div style="font-size: 16px; margin-top: 10px">
              当前可切换URL次数：
              <span style="color: orange">{{ switch_my_page_num || 0 }} </span> 次
            </div>
            <el-button type="primary" @click="changeUrl(3)" :disabled="!switch_my_page_num" class="mt10">切换URL</el-button>
          </div>
        </div>
      </div>
      <div class="address_main">
        <p class="view_title mt20">域名信息</p>
        <div class="view_detail ml20">
          <div style="font-size: 14px; color: rgb(200, 200, 200)">
            请勿随意切换域名，如需切换域名，请联系相关技术人员进行报备
          </div>
          <el-form label-width="100px" label-position="left" class="address_list mt20">
            <el-form-item :label="'首选域名'">
              <el-input v-model="firsthost" disabled></el-input>
              <span style="color: orange" class="ml10" v-if="showUseFirst">正在使用</span>
            </el-form-item>
            <el-form-item label="备用域名">
              <el-input v-model="sparehost" disabled></el-input>
              <span style="color: orange" class="ml10" v-if="showUseSec">正在使用</span>
              <el-button
                type="primary"
                class="ml10"
                @click="changeAddress"
                v-if="showUseFirst"
                >点击切换域名</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
export default {
  name: "setting",
  data() {
    return {
      nodata: "",
      host: "",
      sparehost: "",
      switchNum: 0,
      showUseFirst: false,
      showUseSec: false,
      firsthost: "",
      switch_collection_num: 0,
      switch_seckill_num: 0,
      switch_my_page_num: 0,
    };
  },
  methods: {
    async getConfigDetail() {
      try {
        const res = await ActivityAjax.getConfigDetail();
        if (res.data && res.code == 0) {
          console.log(res);
          this.host = res.data.host;
          this.firsthost = res.data.first_host;
          this.sparehost = res.data.spare_host;
          this.switchNum = res.data.switch_num;
          this.switch_collection_num = res.data.switch_collection_num;
          this.switch_my_page_num = res.data.switch_my_page_num;
          this.switch_seckill_num = res.data.switch_seckill_num;
          // 判断是否首选域名
          this.showUseFirst = false;
          this.showUseSec = false;
          if (res.data.first_host == this.host) {
            this.showUseFirst = true;
          } else if (res.data.host == this.sparehost) {
            this.showUseSec = true;
          }
        } else {
          this.$message.warning(res.msg);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 切换Url
    async updateUrl(type) {
      try {
        const res = await ActivityAjax.updateConfigDetail({
          switch_host: false,
          switch_preheat: type == 0 ? true : false,
          switch_seckill: type == 1 ? true : false,
          switch_collection: type == 2 ? true : false,
          switch_my_page: type == 3 ? true : false,
        });
        if (res.data && res.code == 0) {
          this.$message.success(res.msg);

          this.switchNum = type == 0 ? this.switchNum - 1 : this.switchNum;
          this.switch_collection_num =
            type == 2 ? this.switch_collection_num - 1 : this.switch_collection_num;
          this.switch_seckill_num =
            type == 1 ? this.switch_seckill_num - 1 : this.switch_seckill_num;
          this.switch_my_page_num =
            type == 3 ? this.switch_my_page_num - 1 : this.switch_my_page_num;
          // this.getConfigDetail();
        } else {
          this.$message.warning(res.msg);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 切换备用域名
    async updateAddress() {
      try {
        const res = await ActivityAjax.updateConfigDetail({
          switch_host: true,
          switch_preheat: false,
          switch_collection: false,
          switch_seckill: false,
        });
        if (res.data && res.code == 0) {
          console.log(res);
          this.$message.success(res.msg);
          this.getConfigDetail();
        } else {
          this.$message.warning(res.msg);
        }
      } catch (error) {
        this.$message.error(error);
      }
    },
    changeUrl(type) {
      if (
        (this.switchNum <= 0 && type == 0) ||
        (this.switch_seckill_num <= 0 && type == 1) ||
        (this.switch_collection_num <= 0 && type == 2) ||
        (this.switch_my_page_num <= 0 && type == 3)
      ) {
        this.$message.error("当前无法切换URL，请联系技术人员处理");
      } else {
        this.$confirm(`确定切换URL？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.updateUrl(type);
          })
          .catch(() => {});
      }
    },
    changeAddress() {
      this.$confirm(`确定切换为备用域名？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.updateAddress();
          console.log(233);
        })
        .catch(() => {});
    },
  },
  created() {
    this.getConfigDetail();
  },
};
</script>

<style lang="scss" scoped>
.Setting_wrapper {
  padding: 20px 20px;
  .setting_main {
    .view_detail {
      padding: 20px;
      border: 1px solid rgb(236, 236, 236);
      width: 600px;
      margin-bottom: 10px;
    }
    .view_title {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
      &:before {
        display: inline-block;
        width: 3px;
        height: 23px;
        vertical-align: sub;
        background-color: #419eff;
        line-height: 2;
        margin-right: 20px;
        content: "";
      }
    }
    .address_list .el-input {
      width: 350px;
    }
    .blueColor {
      color: #0084ff;
    }
  }
  .view_wrap {
    display: flex;
    flex-shrink: 0;
    .view_detail {
      width: 50%;
    }
  }
  .url-tips {
    font-size: 15px;
    color: rgb(149 149 149);
    padding-left: 20px;
    margin-bottom: 10px;
  }
}
</style>
