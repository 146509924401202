var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Setting_wrapper" }, [
    _c(
      "div",
      { staticClass: "setting_main" },
      [
        _c("el-alert", {
          attrs: {
            title:
              "温馨提示：若活动页出现”已停止访问该网页“的提示，请优先进行切换URL；若无效，请联系相关技术人员",
            type: "warning",
            closable: false
          }
        }),
        _c("div", { staticClass: "url_main" }, [
          _c("p", { staticClass: "view_title mt20" }, [_vm._v("地址信息")]),
          _c("div", { staticClass: "mt10 url-tips" }, [
            _vm._v(" 如需增加URL切换次数，请联系相关技术人员进行发版处理 ")
          ]),
          _c("div", { staticClass: "view_wrap" }, [
            _c(
              "div",
              { staticClass: "view_detail ml20" },
              [
                _c("div", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("活动首页")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "16px", "margin-top": "10px" }
                  },
                  [
                    _vm._v(" 当前可切换URL次数： "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v(_vm._s(_vm.switchNum || 0) + " ")
                    ]),
                    _vm._v(" 次 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "mt10",
                    attrs: { type: "primary", disabled: !_vm.switchNum },
                    on: {
                      click: function($event) {
                        return _vm.changeUrl(0)
                      }
                    }
                  },
                  [_vm._v("切换URL")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "view_detail ml20" },
              [
                _c("div", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("秒杀页面")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "16px", "margin-top": "10px" }
                  },
                  [
                    _vm._v(" 当前可切换URL次数： "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v(_vm._s(_vm.switch_seckill_num || 0) + " ")
                    ]),
                    _vm._v(" 次 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "mt10",
                    attrs: {
                      type: "primary",
                      disabled: !_vm.switch_seckill_num
                    },
                    on: {
                      click: function($event) {
                        return _vm.changeUrl(1)
                      }
                    }
                  },
                  [_vm._v("切换URL")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "view_detail ml20" },
              [
                _c("div", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("集赞页面")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "16px", "margin-top": "10px" }
                  },
                  [
                    _vm._v(" 当前可切换URL次数： "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v(_vm._s(_vm.switch_collection_num || 0) + " ")
                    ]),
                    _vm._v(" 次 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "mt10",
                    attrs: {
                      type: "primary",
                      disabled: !_vm.switch_collection_num
                    },
                    on: {
                      click: function($event) {
                        return _vm.changeUrl(2)
                      }
                    }
                  },
                  [_vm._v("切换URL")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "view_detail ml20" },
              [
                _c("div", { staticStyle: { "font-size": "20px" } }, [
                  _vm._v("我的页面")
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { "font-size": "16px", "margin-top": "10px" }
                  },
                  [
                    _vm._v(" 当前可切换URL次数： "),
                    _c("span", { staticStyle: { color: "orange" } }, [
                      _vm._v(_vm._s(_vm.switch_my_page_num || 0) + " ")
                    ]),
                    _vm._v(" 次 ")
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "mt10",
                    attrs: {
                      type: "primary",
                      disabled: !_vm.switch_my_page_num
                    },
                    on: {
                      click: function($event) {
                        return _vm.changeUrl(3)
                      }
                    }
                  },
                  [_vm._v("切换URL")]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "address_main" }, [
          _c("p", { staticClass: "view_title mt20" }, [_vm._v("域名信息")]),
          _c(
            "div",
            { staticClass: "view_detail ml20" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "rgb(200, 200, 200)"
                  }
                },
                [
                  _vm._v(
                    " 请勿随意切换域名，如需切换域名，请联系相关技术人员进行报备 "
                  )
                ]
              ),
              _c(
                "el-form",
                {
                  staticClass: "address_list mt20",
                  attrs: { "label-width": "100px", "label-position": "left" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "首选域名" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.firsthost,
                          callback: function($$v) {
                            _vm.firsthost = $$v
                          },
                          expression: "firsthost"
                        }
                      }),
                      _vm.showUseFirst
                        ? _c(
                            "span",
                            {
                              staticClass: "ml10",
                              staticStyle: { color: "orange" }
                            },
                            [_vm._v("正在使用")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备用域名" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.sparehost,
                          callback: function($$v) {
                            _vm.sparehost = $$v
                          },
                          expression: "sparehost"
                        }
                      }),
                      _vm.showUseSec
                        ? _c(
                            "span",
                            {
                              staticClass: "ml10",
                              staticStyle: { color: "orange" }
                            },
                            [_vm._v("正在使用")]
                          )
                        : _vm._e(),
                      _vm.showUseFirst
                        ? _c(
                            "el-button",
                            {
                              staticClass: "ml10",
                              attrs: { type: "primary" },
                              on: { click: _vm.changeAddress }
                            },
                            [_vm._v("点击切换域名")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }